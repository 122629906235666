/* ----------------------------------------------
 * Generated by Animista on 2023-11-7 13:40:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/* animation tilt-in-fwd-br */
.tilt-in-fwd-br {
        -webkit-animation: tilt-in-fwd-br 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: tilt-in-fwd-br 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes tilt-in-fwd-br {
        0% {
                -webkit-transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
                transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
                opacity: 0;
        }

        100% {
                -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
                transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
                opacity: 1;
        }
}

@keyframes tilt-in-fwd-br {
        0% {
                -webkit-transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
                transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
                opacity: 0;
        }

        100% {
                -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
                transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
                opacity: 1;
        }
}


/* animation swing-in-right-bck */
.swing-in-right-bck {
        -webkit-animation: swing-in-right-bck 0.6s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
        animation: swing-in-right-bck 0.6s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@-webkit-keyframes swing-in-right-bck {
        0% {
                -webkit-transform: rotateY(70deg);
                transform: rotateY(70deg);
                -webkit-transform-origin: right;
                transform-origin: right;
                opacity: 0;
        }

        100% {
                -webkit-transform: rotateY(0);
                transform: rotateY(0);
                -webkit-transform-origin: right;
                transform-origin: right;
                opacity: 1;
        }
}

@keyframes swing-in-right-bck {
        0% {
                -webkit-transform: rotateY(70deg);
                transform: rotateY(70deg);
                -webkit-transform-origin: right;
                transform-origin: right;
                opacity: 0;
        }

        100% {
                -webkit-transform: rotateY(0);
                transform: rotateY(0);
                -webkit-transform-origin: right;
                transform-origin: right;
                opacity: 1;
        }
}

.flip-diagonal-2-br {
        -webkit-animation: flip-diagonal-2-br 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
        animation: flip-diagonal-2-br 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* animation flip-diagonal-2-br */
@-webkit-keyframes flip-diagonal-2-br {
        0% {
                -webkit-transform: rotate3d(-1, 1, 0, 0deg);
                transform: rotate3d(-1, 1, 0, 0deg);
        }

        100% {
                -webkit-transform: rotate3d(-1, 1, 0, 180deg);
                transform: rotate3d(-1, 1, 0, 180deg);
        }
}

@keyframes flip-diagonal-2-br {
        0% {
                -webkit-transform: rotate3d(-1, 1, 0, 0deg);
                transform: rotate3d(-1, 1, 0, 0deg);
        }

        100% {
                -webkit-transform: rotate3d(-1, 1, 0, 180deg);
                transform: rotate3d(-1, 1, 0, 180deg);
        }
}

.scale-in-center {
        /* -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
       animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;     */
        -webkit-animation: scale-in-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940);
        animation: scale-in-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    }
    
    @-webkit-keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }
    
    @keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }