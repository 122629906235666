.home-categories {
    width: 100%;
    height: calc(100vh - 204px);
    overflow: hidden;
    padding: 5px 0;
}

.category-column {
    min-width: calc((100% / 3) - 14px);
    height: 100%;
    max-height: calc(100% - 24px);
    padding: 0 7px;
    margin: 0;
    overflow-y: overlay;
    position: relative;
    transition: all 0.9s ease-in-out !important;
}


div.bottom-panel {
    width: 100%;
    height: 40px;
}

div.bottom-panel>button {
    color: #FFFFFF !important;
}

div.bottom-panel>button:first-of-type {
    margin-right: 20px !important;
}

div.bottom-panel>button:last-of-type {
    margin-left: 20px !important;
}

.switch-points {
    width: 20px !important;
    height: 20px;
    color: #FFFFFF;
    cursor: pointer;
    margin: auto 3px !important;
}

.switch-points>svg {
    font-size: 18px;
}

.switch-points>span {
    display: block;
    transition: all 0.3s ease-in-out;
    border: 2px solid #FFFFFF;
    background-color: transparent;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.switch-points:hover>span,
.selected-index>span {
    background-color: #FFFFFF;
}

.selected-index>span {
    transform: scale(1.15);
}

.category {
    width: 100%;
    height: 90px;
    margin: 0 0 20px 0;
    color: rgba(0, 0, 0, 0.5);
    flex-grow: 3;
    font-size: 30px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.295);
    font-weight: 450;
    background-color: #FFFFFF;
    align-self: flex-start;
    font-family: 'Courier New', Courier, monospace;
    position: sticky;
    top: 0;
    z-index: 3000;
}

.bottom-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7px;
    display: block;
    margin: 0;
    background: #9290907e;
}

.category-column:hover>.category>.bottom-border {
    cursor: pointer;

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b4ddb4+0,83c783+17,52b152+33,008a00+67,005700+83,002400+100;Green+3D+%231 */
    background: linear-gradient(to left, rgba(180, 221, 180, 1) 0%, rgba(131, 199, 131, 1) 10%, rgba(82, 177, 82, 1) 33%, rgba(0, 138, 0, 1) 67%, rgba(0, 87, 0, 1) 83%, rgba(0, 36, 0, 1) 100%);
}

.category:hover {
    color: #000000;
    cursor: pointer;
}

.box-list {
    width: 96%;
    margin: auto !important;
}

.box-list-li,
.box-list-li-border {
    width: 100%;
    margin-bottom: 5px;
    background-color: #FFFFFF;
    color: #6e6e6e;
    border-bottom: 3px solid transparent;
}

li.box-list-li-border {
    border: 1px solid #cccccc;
    border-radius: 3px;
    padding: 12px 20px !important;
}

.box-list-li:hover,
.box-list-li-border:hover {
    cursor: pointer;
    color: #000000;
}

.box-list-li:hover {
    border-color: rgb(78, 158, 67);
}

.box-list-li:hover .box-img {
    background-color: rgb(78, 158, 67);
}

.box-list-li.overview {
    height: 220px;
    align-items: flex-start !important;
    position: relative;
}

.box-list-li.overview>div:first-of-type {
    margin-bottom: 20px;
    width: 100% !important
}

span.supplier-name {
    font-size: 14px;
    font-family: monospace;
    margin-top: -5px;
    display: block;
    color: #06aa81;
}

.box-list-li.overview>div:first-of-type>p {
    width: 100%;
    font-size: 12px;
}

.deadline-span {
    position: absolute;
    top: 55px;
    right: 17px;
    color: #999999;
    margin: 0;
    font-size: 12px;
}

.box-list-li.overview>div:last-of-type {
    position: absolute;
    top: 30px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

li.overview>div>p {
    color: #0029e2;
    font-family: monospace !important;
    letter-spacing: 1px;
}

.MuiChartsLegend-series text {
    font-size: 18px !important;
    letter-spacing: 1.6px !important;
}

@media(min-width: 1025px) {
    .home-categories:not(.flex) {
        height: calc(100vh - 150px);
    }

    div.bottom-panel:not(.bottom-panel.visible) {
        display: none !important;
        flex: none !important;
    }
}

@media(max-width: 1400px) {
    .category-column {
        min-width: calc(50% - 15px) !important;
    }
}