
.survey-box,
.survey-dropdown-box {
    width: calc(100% - 24px);
    padding: 0 10px;
    flex-wrap: wrap;
}

.survey-box {
    margin-bottom: 50px;
}

.survey-box.d-row {
    margin-bottom: 0;
}

.survey-dropdown-box,
.survey-dropdown-box-view {
    margin-top: 0 !important;
    width: calc(100% - 24px) !important;
    margin: 0 auto;
}

.survey-dropdown-box {
    margin-bottom: 50px !important;
}

.survey-flex-box,
.survey-flex-li,
.diagrams-wrapper {
    margin: 15px 5px;
    background-color: #FFFFFF;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.section-box p.free-text {
    padding: 5px;
    margin: 0 !important;
    font-size: 14px;
    font-family: monospace;
    font-style: italic;
    color: #7a7d8a;
}

.section-question {
    font-weight: 600;
    width: max-content;
}

.section-question >span {
    margin-left: 15px;
    font-size: 13px;
    font-weight: normal;
    color:#4f576a;
    letter-spacing: 0.5px;
}

.diagrams-wrapper {
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
}

.diagrams-wrapper:hover {
    opacity: 1;
}

.survey-flex-box,
.survey-flex-li {
    flex-grow: 2;
    height: 295px;
    max-width: calc(50% - 30px);
}

.survey-label {
    font-weight: 600 !important;
    align-self: flex-start;
    margin-bottom: -10px;
}

.survey-flex-li {
    min-width: calc(100% - 30px);
}

.survey-flex-box>*,
.survey-flex-box li>div, 
.survey-flex-box li>div>span {
    font-size: 15px !important;
    font-family: 'Courier New', Courier, monospace !important;
    text-transform: uppercase !important;
}

.diagrams-wrapper {
    width: calc(100% - 40px);
    padding: 15px;
}

.diagram-box {
    width: 98%;
    height: 370px;
    margin: 3px 5px 3px 5px;   
    height: max-content;
    min-height: 295px;
}

.diagram-box > div.w-100 {
    height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
}

.diagram-box  > div.w-100 > label {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.diagram-box  > div.w-100 > label > span {
    padding: 2px 8px;
    background-color: #0aab22;
    color: #FFFFFF;
    margin-left: 10px;
    font-weight: 600;
}

.diagram-box.margin-border {
    border-bottom: 0.5px solid #0000002f;
    margin-bottom: 15px;
}

.diagram-box .MuiChartsLegend-series text,
.diagrams-wrapper .MuiChartsLegend-series text {
    font-size: 14px !important;
} 