.block-content-wrapper {
    background-color: #FFFFFF;
    height: calc(100% - 30px);
    padding: 5px 20px 15px 20px;
    margin-top: 10px;
    color: #211f1f;
}

.block-header {
    margin: 10px auto 15px auto;
    height: 80px;
    padding-bottom: 7px;
    border-bottom: 2px solid #211f1fcc;
    background-color: #FFFFFF;
    z-index: 3000;
}

.actions-wrapper {
    height: 100%;
}

.search-wrapper {
    height: max-content;
    position: relative;
}

.search-input,
.search-input>* {
    height: 45px;
    border-radius: 5px;
}

.search-wrapper div.Mui-focused fieldset {
    border-color: #211f1f !important;
}

.search-wrapper button {
    position: absolute;
    right: 3px;
}

.actions-wrapper>button {
    border: 1px solid #211f1f;
    border-radius: 5px;
    height: 45px !important;
    width: 45px !important;
}

.actions-wrapper>button {
    margin-left: 10px;
}

.actions-wrapper>button>span>svg,
.actions-wrapper>button>svg {
    font-size: 25px !important;
}

/* #region Inputs */
.input-variants {
    width: 270px;
    max-width: 20%;
    border-left: 1px solid #7e7d7d;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin: auto;
    margin-right: 0;
}

.input-variants>button {
    width: 90%;
    margin: 3px auto;
    text-align: left !important;
    justify-content: flex-start;
}

.input-variants>button>svg {
    font-size: 20px;
    margin-right: 10px;
    color: #211f1f;
}

/* #endregion */

/* #region Form overview */
.form-building-overview {
    margin: 0;
    padding: 10px 0 20px 30px;
    align-self: flex-start;
    width: calc(95% - 30px);
    position: relative;
}

.form-building-overview .form-control {
    position: relative;
    margin-bottom: 15px !important;
    box-shadow: 0 0 1px 1px #c2bdbd7c;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: calc(100% - 15px);
}

.form-building-overview .fields-container,
.fields-container:not(.box-none>.form-control>.fields-container):not(.box-none .fields-container) {
    width: calc(100% - 60px) !important;
    padding: 10px 30px !important;
    margin-bottom: 20px;
}

.form-building-overview .form-control:hover {
    border-color: #024897 !important;
}

.form-building-overview>div:not(.form-control) {
    width: 100%;
}

.missed-label {
    font-size: 20px !important;
    font-style: italic !important;
    color: #cc0000;
    margin: 0 0 -10px 0 !important;
}

.fbo-description {
    margin: 0;
    padding: 25px 20px;
    width: calc(100% - 24px) !important;
    background-color: rgba(22, 165, 237, 0.05);
    font-size: 20px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.missed-description {
    font-size: 16px;
    background-color: rgba(86, 86, 86, 0.05);
    opacity: 0.5;
    font-style: italic !important;
}

.item-actions {
    height: 70px !important;
    background: #02346615;
    margin-top: 20px;
}

.item-actions:not(.w-100) {
    width: calc(100% + 30px) !important;
    margin-bottom: -30px;
    padding: 0 15px !important;
}

div[role="dialog"] {
    padding: 10px !important;
    width: 95% !important;
    max-width: 450px;
    min-height: 200px
}

.item-actions>button:disabled {
    opacity: 0.25 !important;
}

.item-actions svg {
    font-size: 25px;
}

.item-actions>label {
    margin-left: 10px;
}

/* #endregion */

/* #region Tooltip */
.tooltip-default,
.tooltip-info,
.tooltip-white,
.tooltip-red {
    padding: 10px 12px !important;
    font-size: 12px !important;
    text-align: center !important;
    cursor: alias !important;
    letter-spacing: 0.5px !important;
    z-index: 3000 !important;
    color: #FFFFFF !important;
    letter-spacing: 1px !important;
}

.tooltip-info {
    background-color: #1976D2 !important;
}

.tooltip-white {
    background-color: #FFFFFF !important;
    color: #202020 !important;
    border: 1px solid #a8a8a8 !important;
    font-size: 12px !important;
    padding: 20px !important;
    max-width: 200px !important;
}

.tooltip-white,
.tooltip-multiline {
    text-transform: uppercase !important;
    text-align: left !important;
}

.tooltip-red {
    background-color: #cc0000 !important;
    text-transform: uppercase !important;
}

.tooltip-multiline {
    font-size: 12px !important;
    padding: 10px 20px !important;
    line-height: 20px !important;
}

.tooltip-arrow-red {
    color: #cc0000 !important;
}

.tooltip-arrow-info {
    color: #1976D2 !important;
}

.tooltip-arrow-white {
    color: #a8a8a8 !important;
}

.tooltip-span {
    display: block;
    width: max-content;
    margin-left: 10px;
    font-size: 9px;
    color: #079481
}

/* #endregion */