.service-work {
    background: url(../images/service.png), #092574;
    background-size: 700px auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    width: 700px;
    height: 500px;
    display: block;
    overflow: hidden;
}

.service-work-message {
    padding: 20px;
    color: #FFFFFF;
    text-align: center;
    font-family: monospace;
    text-transform: uppercase;
    letter-spacing: 1px;
}