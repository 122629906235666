/* #region Hidden menu */
.hidden-menu-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3500;
}

.hidden-menu {
    width: 400px;
    max-width: 94%;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.5);
    height: 100%;
    display: block;
    background-color: #FFFFFF;
    z-index: 3000;
    margin: auto 0;
  }
  
  .hm-header {
    height: 100px;
    width: calc(100% - 20px);
    padding: 0 10px;
    margin: auto;
    background-color: rgba(0, 87, 0, 1);
  }
  
  .hm-header>button {
    width: 50px;
    height: 50px
  }
  
  .hm-header svg {
    font-size: 30px;
    color: #FFFFFF;
  }
  
  .hm-wrapper {
    width: 85%;
    height: calc(100% - 140px);
    margin: 20px auto;
    align-self: flex-start;
  }
  
  .hm-categories {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .hm-category-link,
  .hm-logout {
    padding: 8px 0;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin: 3px 0 !important;
    color: #211f1f;
    width: calc(100% - 2px);
    border: 1px solid #211f1f45;
  }
  
  .hm-category-link:hover,
  .hm-category-link.selected {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b4ddb4+0,83c783+17,52b152+33,008a00+67,005700+83,002400+100;Green+3D+%231 */
    background: linear-gradient(to left, rgba(180, 221, 180, 1) 0%, rgba(131, 199, 131, 1) 10%, rgba(82, 177, 82, 1) 33%, rgba(0, 138, 0, 1) 67%, rgba(0, 87, 0, 1) 83%, rgba(0, 36, 0, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  
  .hm-category-link:hover,
  .hm-logout:hover,
  .hm-category-link.selected {
    color: #FFFFFF;
    cursor: pointer;
    padding: 9px 1px;
    border: none;
    letter-spacing: 3px;
  }

  button.hm-add-category {
    float: right !important;
    margin: 10px 0 30px 0 !important;
    font-size: 11px !important;
    color: #302f2f;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    padding: 0 !important;
  }

  button.hm-add-category:hover {
    background-color: transparent !important;
    color: rgb(13, 81, 182) !important;
  }

  button.hm-add-category svg {
    font-size: 10px !important;
  }
  
  .hm-logout:hover {
    background-color: rgb(1, 19, 5, 0.9);
  }
  
  .hm-wrapper p>svg {
    margin: auto 10px;
  }
  
  /* #endregion */