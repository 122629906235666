form {
    width: 95%;
    margin: 10px auto;
}

form.disabled {
    cursor: no-drop !important;
    pointer-events: "none" !important;
}

div.form-control {
    width: 100%;
    max-width: vw;
    margin-top: 10px !important;
    display: block;
    position: relative;
}

.form-control h2 {
    width: 97% !important;
    margin: 30px auto !important;
}

div.form-control:not(.login-container div.form-control):not(.variants-fields div.form-control):not(.box-none div.form-control):not(.form-control.box-none) {
    margin-bottom: 50px !important;
    box-shadow: 0 0 1px 1px #c2bdbd7c;
}

.box-none .form-control:not(.variants-fields div.form-control) {
    margin-bottom: 30px !important;
    box-shadow: unset !important;
}

div.form-control.none {
    margin: 0 auto !important;
}

div.form-control>*:not(div[role="alert"]):not(textarea):not(.fbo-description),
div.fields-container>*:not(div[role="alert"]):not(textarea) {
    width: 100%;
}

div.fields-container.rating>* {
    width: max-content !important;
    margin: 0 !important;
    padding: 30px 10px !important;
    align-self: flex-start !important;
}

div.form-control>*:not(div[role="alert"]):not(.item-actions):not(.label-wrapper):not(.label-view):not(textarea),
div.fields-container>*:not(div[role="alert"]):not(.item-actions):not(.label-wrapper):not(.label-view):not(textarea) {
    justify-content: flex-start;
}

.fields-container .MuiInputBase-root,
.fields-container textarea {
    margin-bottom: 20px;
}

form .form-control {
    margin-right: auto !important;
    margin-left: auto !important;
}

.form-control label {
    margin-right: 0 !important;
    justify-content: flex-start;
}

div.form-control>span>span[style*="left: 0%;"] {
    left: 10px !important;
}

div.form-control>span>span[style*="left: 100%;"] {
    left: calc(100% - 18px) !important;
}

div.form-control textarea {
    border-color: rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    height: auto !important;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    min-height: 75px;
    width: calc(100% - 32px);
    padding: 12px 15px !important;
}

div.form-control textarea:focus {
    border: 1px solid rgba(143, 142, 142, 0.866) !important;
    outline: none !important;
}

div.form-control>.question-number {
    position: absolute;
    right: 10px;
    bottom: 10px;
    transform: scale(0.7);
    width: max-content !important;
    opacity: 0.5 !important;
}

.label-wrapper,
.label-view:not(.box-none .label-view) {
    width: calc(100% - 40px) !important;
    padding: 20px !important;
}

.label-wrapper {
    background-color: aliceblue;
    margin-bottom: 5px;
}

.label-view:not(.box-none .label-view) {
    background-color: rgba(205, 218, 230, 0.26);
    margin-bottom: 15px;
}

.labels>label {
    min-width: 100px !important;
}

label.checkbox-label {
    margin-left: -10px !important;
    margin-top: 20px !important;
    min-width: max-content !important;
}

label.single-checkbox>div {
    min-width: max-content;
}

label.checkbox-label span:last-of-type span {
    font-family: monospace !important;
    font-size: 16px !important;
}

.boxes-wrapper {
    margin-top: 40px !important;
    margin-bottom: 20px !important;
}

.box-parameters {
    padding: 10px 15px !important;
    margin: 0 !important;
}

.box-parameters label {
    margin-top: 0 !important;
}

div[role="radiogroup"] {
    margin: 10px 0 !important;
}

div[role="radiogroup"]>label {
    display: flex !important;
}


div#checkbox>label>span:last-of-type {
    width: 100% !important;
}

div[role="radiogroup"]>label>span:last-of-type,
div[role="radiogroup"]>label>span:last-of-type>span,
div#checkbox>label>span:last-of-type>span {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

div[role="radiogroup"]>label>span:last-of-type>span>span:last-of-type {
    padding: 3px 5px;
    border: 1px solid #838282;
    color: cornflowerblue;
    margin-left: 20px;
    min-width: 70px;
    text-align: center;
    background: transparent;
    border-radius: 3px;
}

label>span:last-of-type>span>span:last-of-type {
    padding: 3px 5px;
    color: cornflowerblue;
    margin-left: 20px;
    background: transparent;
    border-radius: 3px;
}

.form-control .label,
.send-form .label {
    font-weight: 600;
}

.send-form label.label:not(.d-row>.label) {
    margin-bottom: 15px;
}

div.info-dropdown {
    padding: 0 10px;
    overflow: hidden;
    font-size: 14px;
    border: 0 solid rgba(107, 199, 241, 0.267);
    transition: all .7s ease-in-out;
    border-radius: 3px;
    width: -webkit-fill-available !important;
    width: calc(100% - 22px) !important;
    margin: 5px auto !important;
    overflow: hidden;
    font-style: italic;
    font-family: monospace !important;
    height: max-content !important;
    max-height: 0;
    background-color: rgba(107, 199, 241, 0.082);
    color: rgb(65, 64, 64);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: calc(100% - 42px) !important;
}

.info-dropdown.visible {
    padding: 20px 10px;
    max-height: 1000px;
    margin-bottom: 40px !important;
    border-width: 1px;
    text-align: left;
}

.info-dropdown>span {
    padding: 0;
    margin-left: 20px;
}

div[role="dialog"] {
    display: flex;
}

.form-control>div.d-row:not(.info-dropdown) {
    min-height: 30px;
}

.form-control>div.d-row>svg {
    font-size: 20px;
    cursor: help;
}

::placeholder {
    font-size: 14px !important;
}

.default-value ::placeholder {
    font-weight: 500 !important;
    opacity: 1 !important;
    font-size: 16px !important;
}

span.MuiFormLabel-asterisk {
    color: #cc0000 !important;
}

/* #region Form building */
.form-building-container {
    width: 100%;
    height: calc(100% - 105px);
}

.form-building-wrapper,
.form-building-hover {
    height: 100%;
    height: -webkit-fill-available;
    width: 100%;
    margin-right: 0;
    box-shadow: transparent;
    position: relative;
    overflow-y: auto;
    position: relative;
}

.form-building-wrapper .response-box {
    margin-right: 10px;
}

.form-building-hover {
    box-shadow: 0 0 2px 2px #8e8e8e87 !important;
    opacity: 0.35 !important;
}

.fb-message-div {
    width: max-content;
    font-size: 20px;
    margin: auto;
    font-family: monospace;
    text-transform: uppercase;
}

.fb-message-div>svg {
    font-size: 70px;
    margin: auto;
}

.fb-modal {
    margin: 0 auto;
    width: 90%;
    padding: 15px 30px;
    height: auto;
    background-color: #FFFFFF;
    position: absolute;
    top: 0;
    z-index: 3000;
    display: block !important;
}

.fb-modal>*:not(button) {
    width: 100% !important;
}

.fb-modal>label {
    margin-bottom: 25px;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
}

.fb-modal>label>svg {
    margin-right: 10px;
    color: rgb(169, 7, 29);
    font-size: 30px;
}

.fb-modal>div.w100:not(.form-control) {
    border-bottom: 3px solid #A9071D;
    margin-bottom: 60px !important;
}

.variants-container {
    margin-bottom: 30px;
}

.variants-container>*,
.variants-fields-wrapper>* {
    width: 100%;
    width: -webkit-fill-available;
}

.variants-container>label>span {
    text-transform: uppercase !important;
    font-size: 12px;
    letter-spacing: 1px;
    margin-right: 0 !important;
}

.dropdown-list {
    width: calc(100% - 6px) !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    margin: 25px 0;
    flex-grow: 1 !important;
}

.dropdown-list.error {
    border-color: #c50303 !important;
}

.dropdown-list:hover {
    border-color: #dee0e0c4 !important;
}

.connection-box {
    padding: 0;
    margin-top: 15px;
}

.connection-box>div.dropdown-list {
    margin: 0 !important;
}

.connection-box>div>button {
    width: calc(100% - 20px) !important;
    text-align: left !important;
    margin: 2px !important;
    justify-content: flex-start !important;
}

.connection-box>div.form-control {
    height: 100%;
    max-height: 350px;
    margin-top: 5px !important;
    padding: 20px 10px;
    border: 1px solid #ccc;
    overflow-y: auto;
    width: calc(100% - 26px) !important;
}

.connection-box .label {
    width: calc(100% - 6px) !important;
    margin-bottom: 10px !important;
    height: max-content !important;
}

.fbm-input {
    margin-bottom: 30px !important;
}

.fbm-input input:disabled {
    background-color: #bfbfbf76 !important;
    color: #000000 !important;
    -webkit-text-fill-color: #000000 !important;
    font-weight: 600 !important;
    opacity: 1 !important;
    cursor: no-drop !important;
}

.connection-box .fbm-input {
    margin-top: 30px !important;
}

.variants-switch {
    width: max-content !important;
}

.variants-fields-wrapper .fbm-input,
.variants-fields-wrapper .fbm-input>div {
    margin-bottom: 0 !important;
    height: 55px !important;
}

.variants-fields-wrapper .fbm-input textarea {
    overflow-y: auto !important;
}

.variants-fields-wrapper .fbm-input:nth-child(2) {
    margin: 0 0 0 5px !important;
    max-width: 100px !important;
}

.points-buttons {
    width: 55px !important;
    height: 55px !important;
    padding: 0 !important;
    margin-left: 5px !important;
}

.variants-input>* {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: 55px !important;
}

.variants-input fieldset {
    margin-top: 0 !important;
    border-width: 1px !important;
    border-color: rgba(0, 0, 0, 0.23) !important;
}

.variants-wrapper {
    padding: 5px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0 0 3px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-top: 0;
    overflow: hidden;
}

.variants-list-wrapper {
    max-height: 300px;
    overflow-y: auto;
}

.variants-wrapper>button:not(.list-button) {
    margin: 5px 3px !important;
    width: auto;
    min-width: calc(20% - 6px);
    padding-right: 34px;
    float: left;
    justify-content: flex-start !important;
    border-color: rgba(0, 0, 0, 0.23) !important;
    position: relative;
    text-transform: none !important;
}

.list-variant {
    border: 1px solid #318CE7;
    color: #318CE7;
    border-radius: 3px;
    padding: 5px 0;
    margin: 2px !important;
    cursor: pointer;
}

.list-variant:hover {
    background-color: #318CE7 !important;
    color: #FFFFFF !important;
}

.list-variant>div>span {
    margin: auto 5px auto 10px !important;
    font-size: 11px;
}

.list-variant>div>svg {
    margin-left: 7px !important;
    font-size: 18px;
}

.variant-btns {
    align-self: flex-end;
    height: 100%;
}

.variant-btns>button>svg {
    font-size: 16px;
}

.variants-wrapper>button>svg:first-of-type {
    font-size: 18px;
    margin-right: 10px;
}

.variants-wrapper>button>svg:last-of-type {
    font-size: 12px;
    color: #cc0000;
    margin: auto;
    position: absolute;
    right: 10px;
}

ul>li[role="option"] {
    min-height: 45px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.select-wrapper {
    min-width: 200px !important;
}

div#menu-[role="presentation"] {
    z-index: 3000;
}

button:disabled {
    cursor: no-drop !important;
}

.choice-buttons {
    margin: 15px 0;
    width: 100%;
}

.choice-buttons>button {
    margin-right: 10px;
}

.checkbox-wrapper {
    margin: 20px 0 0 0 !important;
    width: -webkit-fill-available !important;
    text-transform: uppercase;
    padding: 0;
}

.checkbox-wrapper:not(.no-margin) {
    padding: 0 10px !important;
}

.checkbox-wrapper.no-margin svg {
    margin-left: -11px;
}

.checkbox-wrapper>span,
label#connected>div>span {
    font-size: 12px !important
}

.checkbox-mode {
    font-size: 14px !important;
    letter-spacing: 2px;
    padding-right: 10px !important;
    padding-left: 10px !important;
    color: #00b4ab !important;
    position: absolute !important;
    left: 0 !important;
    margin-bottom: 0 !important;
}

.checkbox-mode>div>span:last-of-type {
    color: #00b4ab !important;
}

label#connected>div>span:last-of-type {
    color: #cc0000 !important;
}

/* #endregion */

/* #region Form view */
.view-form-wrapper,
.view-wrapper,
.view-survey,
.view-form-loading {
    color: #000000;
    background-color: #ffffff96;
    position: relative;
    height: 100%;
    max-height: 1000%;
}

.view-survey,
.view-form-loading {
    max-width: 1400px;
    margin: 0 auto;
}

.view-survey:not(.container .view-survey) {
    box-shadow: 0 0 1px 1px #dad5d5
}

.view-form-loading>*:not(.wrapper) {
    opacity: 0;
    z-index: -1;
}

.view-form-loading>.wrapper {
    opacity: 1;
    z-index: 3000;
    position: absolute;
    top: 0;
    left: 0;
} 

.view-form-wrapper,
.view-survey,
.view-wrapper {
    background-color: #FFFFFF;
    height: 100%;
    overflow-x: hidden;
    padding-bottom: 40px;
}

.view-survey>div {
    margin: 10px 30px !important;
}

.view-form-wrapper,
.view-wrapper {
    overflow-y: scroll;
}

.view-form-wrapper {
    padding-bottom: 50px;
}

.view-wrapper {
    padding: 0 30px 40px 30px;
    width: calc(100% - 60px);
}

.view-survey {
    padding-bottom: 20px;
}

.view-survey:not(.container .view-survey){
    height: max-content;
    min-height: calc(100vh - 20px);
}

.form-wrapper {
    max-width: 700px;
    margin: 50px auto 0 auto;
}

.form-wrapper .form-buttons {
    padding-top: 0 !important;
    margin-top: 0 !important;
}

.vs-box {
    height: 140px;
    border-bottom: 1.8px solid #75757557;
    width: 100%;
    margin: 0 auto 50px auto;
}

.vs-logo {
    transform: scale(0.7);
    margin-right: -40px;;
}

.vs-deadline {
    font-size: 13px;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    letter-spacing: 0.5px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 0;
}

.vs-deadline>span:first-of-type {
    text-transform: uppercase !important;
    padding: 3px 10px;
    color: #cc0000;
    margin-right: 10px;
}

.vs-deadline>span:last-child {
    display: inline-block !important;
    width: 120px !important;
    text-align: left;
    color: #757575;
}

.survey-edit>span>svg {
    font-size: 16px !important;
}

.container-empty>.view-wrapper {
    min-height: calc(100vh - 40px);
    overflow-y: auto;
}

.short-information {
    border: 1px solid #7b7b7ba0;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 3px;
    font-family: monospace;
}

.short-information>p {
    margin: 4px 0;
    letter-spacing: 0.5px;
}

.short-information>p>span {
    font-weight: 600;
    margin-right: 10px;
    background-color: rgb(21, 127, 193);
    color: #FFFFFF;
    padding: 4px 5px;
    width: 120px;
    display: inline-block;
}

.short-information>p>button {
    padding: 0 !important;
    text-transform: none !important;
}

.view-form-wrapper .form-building-container {
    margin: auto;
    width: 95%;
}

.post-form {
    margin-top: 0 !important;
    width: 100%;
}

.post-form>* {
    margin: 5px 0 !important;
    padding: 0 !important;
}

form.view-form {
    width: 85% !important;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-top: 50px !important;
}

form.post-form div:not(.form-buttons) {
    width: 100% !important;
}

.send-form {
    width: 1100px;
    margin: 80px auto 0 auto !important;
    max-width: 90%;
}

.send-form>form textarea {
    width: calc(100% - 30px) !important;
}

.view-form-wrapper .form-building-container {
    height: calc(100% - 130px);
}

.view-form-content:not(.view-survey .view-form-content){
    margin: 0 20px;
}

.view-form-content>.fbo-description {
    margin-top: 30px;
}

.view-form-content form {
    width: 100%;
    height: auto !important;
}

.view-form-panel {
    padding: 10px 20px;
    opacity: 1 !important;
    position: sticky;
    top: 0;
    z-index: 3000 !important;
    margin-bottom: 30px;
    background-color: #FFFFFF;
}

.view-form-panel>div {
    box-shadow: 0 0 1px 1px #a0a0a0;
    height: 60px;
    width: 100%;
    width: -webkit-fill-available;
    padding: 5px;
}

.view-form-panel>div>p {
    color: #757575;
    margin: 0 0 0 10px;
    font-size: 18px;
    text-transform: uppercase;
    font-family: monospace;
    letter-spacing: 1px;
}

.view-form-panel .search-wrapper {
    margin-right: 15px !important;
}

.form-building-wrapper>.dropdown-list {
    margin: 0 50px 10px 10px !important;
    width: calc(100% - 60px) !important;
}

/* #endregion */

/* #region Form send */
.send-form .variants-container {
    margin-top: 40px;
    margin-bottom: 0 !important;
}

.send-form .fields-container>div {
    margin-bottom: 0 !important;
}

.send-form form {
    margin-top: 30px;
}

.send-form .variants-wrapper {
    display: flex;
    flex-direction: row;
}

.send-form .variants-wrapper>button {
    flex-grow: 4;
}

.send-form>div.w100 {
    position: relative;
}

.send-form>.w100>.blink-color {
    width: 100%;
    height: 56px;
    position: absolute;
    bottom: 0;
    z-index: 3000;
    display: block;
    border-radius: 3px;
}

.send-form #checkbox {
    margin-top: 0 !important;
}

.form-control #checkbox>svg {
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px !important;
    margin-bottom: 15px !important;
}

.variants-fields>div.form-control {
    margin-bottom: 0 !important;
}

.variants-fields>div:first-child {
    margin: 0 !important;
    width: 70% !important;
}

.variants-fields>div:last-child {
    margin: 0 !important;
    width: 29% !important;
}

.variants-fields>div>.info-dropdown {
    display: none !important;
}

/* #endregion */

/* #region Buttons */
.form-buttons {
    width: 100%;
    margin: 20px 0 10px 0;
    padding: 30px 0;
    text-align: right;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.79+0,1+100 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.fb-modal .form-buttons {
    margin: 10px 0 0 0 !important;
}

.form-buttons.position-sticky {
    position: sticky;
    bottom: -10px;
    left: 0;
    z-index: 1000;
}

.position-sticky>button:not(button:first-of-type):not(.bg-unset),
.position-sticky>span>button,
.position-sticky .Mui-disabled {
    background-color: #FFFFFF !important;
}

.position-sticky .Mui-disabled {
    opacity: 1 !important;
    color: #0000006f !important;
}

.form-buttons>span>button,
.form-buttons>button:not(button:first-of-type) {
    margin-left: 10px;
}

.confirm-question {
    color: #cc0000 !important;
    margin: auto 10px auto 0;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.form-buttons>button {
    min-height: 37px !important;
    margin-left: 10px !important;
}

.form-buttons>.submit-btn {
    min-width: 100px;
}

.submit-btn-hidden {
    display: none !important;
    z-index: -1 !important;
    visibility: hidden !important;
}

.disabled input {
    background-color: #f5f5f5e4;
}

.disabled input:hover {
    cursor: no-drop !important;
}

/* #endregion */

/* #region Upload file */
.upload-file-container {
    border: 2px dashed #8e8e8e87;
    height: 250px;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    font-size: 20px !important;
    color: #8e8e8ec2;
}

.upload-file-container svg {
    font-size: 70px;
}

.upload-file-container>span {
    display: block;
    position: absolute;
    font-size: 12px;
    bottom: 0;
    right: 0;
    background-color: rgb(1, 98, 159);
    color: #FFFFFF;
    margin: 0 10px 10px 0;
    font-family: monospace;
    padding: 7px 15PX;
    text-align: right;

}

/* #endregion */

/* #region Response */
.response-box {
    color: #000000 !important;
    height: max-content;
    width: 100%;
    margin: 0 auto 20px auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.response-box>div {
    justify-content: flex-start;
    max-width: 100%;
    margin-bottom: 10px;
    width: calc(100% - 40px);
    padding: 20px;
}

.response-box>div>* {
    margin: 0;
    font-weight: 600;
}

.response-box .MuiAlert-message {
    width: 100%;
    width: -webkit-fill-available;
}

.response-message {
    width: auto;
    margin: auto 10px;
    font-size: 16px;
    letter-spacing: 1px;
}

.response-box .error-message {
    margin: 0 auto;
    font-size: 16px;
    font-style: italic;
    color: #FFFFFF;
    width: auto !important;
    display: block;
    padding: 12px;
    max-width: 100%;
    margin-top: 15px;
    border-radius: 3px;
    background-color: rgba(204, 0, 00, 0.2);
}

/* #endregion */

/* #region Modal */
svg.modal-svg {
    display: block !important;
    margin: 0 auto !important;
    width: 120px !important;
    height: 120px !important;
}

/* #endregion */