/* Provide sufficient contrast against white background */
body {
  margin: 0;
  padding: 0;
  background: url("./../images/background.jpg"), transparent;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}

main {
  overflow: hidden;
  height: 100vh;
}

.d-row,
.d-column {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.d-column {
  flex-direction: column;
}

.d-row {
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap !important;
}

.r-wrap {
  flex-flow: row wrap !important;
  height: max-content !important;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.ai-start {
  align-items: flex-start;
}

.ai-end {
  align-items: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

main {
  color: #FFFFFF;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.container {
  width: 100% !important;
  padding: 0 !important;
  height: calc(100vh - 130px);
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 1400px !important;  
  position: relative;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  /* margin-top: 20px !important;
  margin-bottom: 10px !important; */

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0) 100%);
}

.bg-white {
  background-color: #FFFFFF !important;
}

.none {
  display: none !important;
  visibility: hidden !important;
  z-index: -1 !important;
}

body .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.6);
}

.curtain {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.39);
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90, 0.2);
}

::-webkit-scrollbar-track {
  background: rgba(228, 226, 226, 0.2);
}

.wrapper,
.modal-wrapper {
  width: 100%;
  height: 100%;
}

.row-wrapper {
  width: max-content;
}

.row-wrapper>* {
  margin-left: 5px;
  padding: 0 !important;
}

.height-filled {
  width: 100%;
  height: -webkit-fill-available;
}

.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffffcf;
  z-index: 3000;
}

.dots {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.w100 {
  width: 100% !important;
}

.w-100 {
  width: -webkit-fill-available !important;
  width: -moz-available !important;
}

.wh-100 {
  width: -webkit-fill-available;
  width: -moz-available;
  height: 100% !important;
  height: -webkit-fill-available;
  height: -moz-available;
  height: fill-available;
  overflow-y: auto;
}

.no-margin {
  margin: 0 !important;
}

.bg-inherit {
  background-color: inherit !important;
}

.loading-message {
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.8;
  font-family: monospace;
}


.disabled,
.disabled>svg,
.Mui-disabled,
.Mui-disabled>svg {
  opacity: 0.5 !important;
  pointer-events: none !important;
  color: #000000 !important;
}

.blink-color,
.warning-color {
  background-color: transparent;
  animation-iteration-count: 1;
  animation-duration: 3s;
}

.blink-color {
  animation-name: greenColor;
}

.warning-color {
  animation-name: warningColor;
}

.delay {
  animation-iteration-count: 0 !important;
}

@keyframes greenColor {
  0% {
    background-color: #0aeba752
  }

  100% {
    background-color: transparent
  }
}

@keyframes warningColor {
  0% {
    background-color: #eb0a1552
  }

  100% {
    background-color: transparent
  }
}

.fade-in-slow {
  animation-name: opacity-slow;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
}

@keyframes opacity-slow {
  0% {
    opacity: 0
  }

  10% {
    opacity: 0.1
  }

  20% {
    opacity: 0.2
  }

  30% {
    opacity: 0.3
  }

  40% {
    opacity: 0.4
  }

  50% {
    opacity: 0.5
  }

  60% {
    opacity: 0.6
  }

  70% {
    opacity: 0.7
  }

  80% {
    opacity: 0.8
  }

  90% {
    opacity: 0.9
  }

  100% {
    opacity: 1
  }
}

.fade-in {
  animation-name: opacity;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  /* animation-delay: 3s; */
}

@keyframes opacity {
  0% {
    opacity: 0.5
  }

  20% {
    opacity: 0.6
  }

  40% {
    opacity: 0.7
  }

  60% {
    opacity: 0.8
  }

  80% {
    opacity: 0.9
  }

  100% {
    opacity: 1
  }
}