.login-container {
    height: 100vh;
    padding: 0 !important;
    margin: 0 !important;
    position: fixed;
    top: 0;
    left: 0;
}

.login-wrapper {
    width: 90%;
    max-width: 500px !important;
    min-height: 400px;
    max-height: 70%;
    padding: 25px 35px 0 35px;
    margin: auto;
    box-shadow: 0 0 2px 2px #eae5e5;
    border-radius: 5px;
    color: #000000;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    position: relative;
    background: url("./../images/background.jpg"), #FFFFFF;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-attachment: local;
    background-size: cover;
    overflow: hidden;
}

.login-form {
    margin: 20px auto;
    padding: 15px 30px;
    background: #0e973c;
    height: 50px;
    color: #FFFFFF !important;
    box-shadow: 0 0 2px 2px rgba(22, 134, 61, 0.575);
    border-radius: 5px;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f6e6b4+0,ed9017+100;Yellow+3D+%231 */
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background: linear-gradient(135deg, rgb(100, 245, 151) 20%, rgb(2, 95, 33) 100%);
}

.login-wrapper>h2 {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #FFFFFF;
}

.login-button {
    letter-spacing: 1px !important;
    padding: 8px 20px !important;
    min-width: 120px !important;
    color: #033b16 !important;
}

.login-button,
.login-form label>* {
    font-size: 16px !important;
}

.login-form svg {
    color: #FFFFFF !important;
}