.header-container {
  height: 120px;
  width: 100%;
  background-color: #ffffff70;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  color: #000000;
  border-bottom: 10px solid #0E973C; 
  
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
  background: linear-gradient(to top, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.5) 90%);
 
}

.header-wrapper {
  height: 100% !important;
  width: 98% !important;
  max-width: 1400px !important;
  margin: auto;
}

/*  #region Logotype */
.logotype {
  height: max-content;
  width: max-content;
  max-width: 50%;
}

.logotype svg {
  font-size: 110px;
  color: #0E973C;
  margin-right: 10px;
}

.logotype>div.d-column:not(.hm-logotype>div.d-column) {
  align-items: flex-end;
}

.logotype>div>p {
  margin: 0;
  font-size: 30px;
}

.logotype>div>p:first-of-type {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
}

.logotype>div>p:last-of-type,
.logotype-subtitle {
  text-align: left;
  font-size: 16px;
  font-style: italic;
  font-family: monospace;
  color: #0E973C;
  margin-bottom: 15px;
  width: 200px;
}

.logotype-subtitle > span {
  display: block;
  width: 100%;
  text-align: right;
}
 
.hm-logotype {
  height: 70% !important;
  max-width: max-content !important;
  color: #FFFFFF !important;
}

.hm-logotype>div.d-column {
  align-items: flex-start;
}

.hm-logotype svg {
  font-size: 70px !important;
  color: #fcfcfc !important;
}

.hm-logotype > div {
  margin-bottom: 10px !important;
}

.hm-logotype>div>p {
  margin: 0 !important;
  font-size: 18px !important;
}

.hm-logotype>div>p:last-of-type {
  color: #FFFFFF !important;
  font-size: 12px !important;
}
/* #endregion */

/* #region Menu */
.menu-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  width: 70%;
}

.menu {
  margin-top: 10px;
}

button.menu-link {
  min-height: 55px;
  min-width: 55px;
  margin: auto 5px;
  border-radius: 15px;
  border: 2px solid #0E973C;
  background: rgba(255, 255, 255, 0.7);
  overflow: hidden;
}

.menu-link > span {
  display: none;
}

button.menu-link:hover {
  background: #FFFFFF;
}

.menu-link > svg {
  font-size: 28px;
  color: #0E973C;
}

.menu-link.selected > svg {
  color: #161717;
}

.menu-link:hover > svg {
  color: #0E973C;
}
/* #endregion */