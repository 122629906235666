.notfound-container {
    background-color: #FFFFFF;
    height: 100%
}

.notfound-container:not(.container .notfound-container) {
    background-color: #47474756 !important;
    width: 100vw !important;
    height: 100vh !important;
}

.notfound-wrapper {
    width: 95%;
    max-width: 700px;
    height: 500px;
    max-height: 50vh;
    box-shadow: 0 0 3px 3px #0E973C;
    background: url('../images/notfound.jpg'), #681800;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.notfound-content {
    position: relative;
    height: max-content;
    width: max-content;
}

.notfound-title {
    font-size: 40px;
    color:#303030;
    text-transform: uppercase;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
}

.notfound-btn {
    font-size: 20px !important;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    letter-spacing: 1px !important;
    position: absolute !important;
    right: 0;
    bottom: -60px;
    padding: 3px 12px !important;
    border-color: #0E973C !important;
    color:#0E973C !important;
    background-color: #FFFFFF85 !important;
}

.notfound-btn:hover {
    background-color: #0E973Cb7 !important;
    color: #FFFFFF !important;
}

.notfound-btn svg {
    font-size: 35px !important;
}

