.section-container {
    width: 98%;
    height: 100%;
    color: #211f1f;
}

.section-right-block,
.section-left-block {
    height: calc(100% - 30px);
    width: 100%;
    padding: 0;
    transition: all 0.5s ease-in-out;
    margin: auto 0;
}

.section-right-block {
    overflow: hidden;
}


.section-left-block {
    overflow-x: hidden;
    overflow-y: auto;
}

/* #region Left block */
.section-left-block {
    width: 300px;
    margin-right: 15px;
    position: relative;
}

.slb-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.slb-buttons>button {
    flex-grow: 2;
    max-width: 46%;
    margin: auto;
    color: #FFFFFF !important;
    border-color: #FFFFFF !important;
}

.section-left-block.hidden {
    width: 0;
    margin-right: 0;
}

.section-category {
    margin-bottom: 10px;
    height: 80px;
    width: 100%;
    align-self: flex-start;
    padding-bottom: 12px;
    border-bottom: 2px solid #FFFFFF;
}

.section-category svg {
    font-size: 40px;
    color: #0E973C;
    margin-right: 10px;
}

ul.section-menu {
    height: auto;
    width: calc(100% - 10px);
    margin: 0 auto 0 10px;
    padding: 20px 0;
    align-self: flex-start;
}

ul.section-menu>li {
    width: 100%;
    margin: 5px auto 12px auto;
    text-transform: uppercase;
    font-family: monospace;
    transition: all 0.4s ease-in-out;
    padding: 0 !important;
}

ul.section-menu>li:hover {
    cursor: pointer;
    letter-spacing: 2px;
    color: #000000c5;
}

ul.section-menu>li>div:first-of-type {
    min-width: 45px !important
}

ul.section-menu>li>div:first-of-type>div {
    background-color: #4A4B4B;
}

ul.section-menu>li>div>span,
ul.section-menu>li>div:last-of-type {
    font-size: 15px;
}

ul.section-menu>li>div:last-of-type {
    right: 0 !important;
}

ul.section-menu>li.li-sub {
    width: calc(100% - 15px) !important;
    margin-left: 15px !important;
}

ul.section-menu>li.li-sub>div>span,
ul.section-menu>li.li-sub>div:last-of-type {
    font-size: 12px !important;
    letter-spacing: 1px !important;
}

ul.section-menu>li.active-link {
    color: #096728;
}

ul.section-menu>li>div>div {
    width: 30px !important;
    height: 30px !important;
}

ul.section-menu>li.active-link>div>div {
    background-color: #096728c4 !important;
}

ul.section-menu>li>div>div>svg {
    font-size: 18px;
}

/*  #endregion */

/* #region Right block */
.section-right-block {
    width: 100%;
    width: -webkit-fill-available;
}

.content-list {
    height: calc(100% - 110px) !important;
}

div.MuiDataGrid-row>div[data-field="actions"] {
    list-style: none;
    overflow: visible !important;
}

.row-buttons {
    width: 100%;
    overflow: visible;
    position: relative;
    height: 100%;
}

.row-buttons button:disabled {
    opacity: 0.35 !important;
    cursor: no-drop !important;
}

.row-buttons button:disabled>* {
    color: #777777 !important;
}

ul.row-menu {
    position: absolute;
    top: 25px;
    right: 10px;
    width: max-width !important;
    min-width: 200px !important;
    height: max-content;
    background: #FFFFFF;
    box-shadow: 0 0 2px 2px #b9b6b6;
    padding: 15px 10px;
    overflow: hidden;
    display: none;
    z-index: 3000;
}

.row-menu>li,
.modal-list>li {
    display: flex !important;
    font-family: monospace;
    font-size: 18px;
    text-transform: uppercase;
    color: #00000090;
    letter-spacing: 1px;
    justify-content: space-between !important;
}

.row-menu>li>div>* {
    font-size: 14px !important;
}

.row-menu>li {
    position: unset !important;
    padding: auto !important;
    height: 35px;
    width: 100% !important;
    margin: auto;
}

.row-menu>li>div:first-of-type {
    min-width: 30px !important;
}

div[role="presentation"]>div {
    margin: auto 0 !important;
}

.modal-list>li {
    height: 34px;
    width: 95% !important;
    background: aliceblue;
    margin: 2px 0;
}

.modal-list>li:hover,
.row-menu>li:hover {
    background-color: #5b5a5a3a !important;
    color: #000000 !important;
    cursor: pointer !important;
}

.row-menu>li:hover {
    background-color: #c9c2c23a !important;
}

.modal-list>li>div>span {
    font-size: 14px !important;
}

/* #endregion */

/* #region List */
.span-diagram {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    aspect-ratio: 1;
    color: #5a1313
}
/* #endregion */